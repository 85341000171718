import React from "react";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createFolderPost,
  deleteKonnectFolderGet,
  getFoldersListGet,
  getKonnectsByFolderIdd,
  getKonnectsListGet,
  renameKonnectFolderPut,
} from "../../api-client";

export const fetchFolder = createAsyncThunk(
  "fetchFolder",
  async (body, { rejectWithValue }) => {
    try {
      const response = await getFoldersListGet();
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getKonnectsList = createAsyncThunk(
  "getKonnectsList",
  async (props, { rejectWithValue }) => {
    try {
      const response = await getKonnectsListGet(props);
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getKonnectsByFolder = createAsyncThunk(
  "getKonnectsByFolder",
  async (props, { rejectWithValue }) => {
    try {
      const response = await getKonnectsByFolderIdd(props);
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchKonnectz = createAsyncThunk(
  "searchKonnectz",
  async (props, { rejectWithValue }) => {
    try {
      const response = await getKonnectsListGet(props);
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createFolder = createAsyncThunk(
  "createFolder",
  async (folderName, { rejectWithValue }) => {
    try {
      const response = await createFolderPost(folderName);
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteFolder = createAsyncThunk(
  "renameFolder",
  async (props, { rejectWithValue }) => {
    try {
      const response = await deleteKonnectFolderGet(props);
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const renameFolderCall = createAsyncThunk(
  "renameFolderCall",
  async (props, { rejectWithValue }) => {
    try {
      const response = await renameKonnectFolderPut(props);
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
